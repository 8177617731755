import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';

// Define the query key
const keys = {
  sharepointFileRefs: 'sharepointFileRefs',
};

// Hook to fetch SharePoint file vector store references
export const useSharepointFileRefs = (group_guid) => {
  return useQuery({
    queryKey: ['sharepointFileRefs', group_guid],
    queryFn: async () => {
      if (!group_guid) {
        throw new Error('group_guid is required');
      }
      
      console.log("Fetching SharePoint file refs for group_guid:", group_guid); // Debug log to see if the fetch is called
      const { data } = await axios.get(`${config.apiGateway.sharepoint_file_vs_refs}`, {
        params: {
          group_guid,
        },
      });
      return data;
    },
    enabled: !!group_guid,
    staleTime: 60000,
  });
};

import config from 'constants/apiConfig';
import axios from 'axios';

import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

import parseErrorMessage from 'utils/parseErrorMessage';

import {
  GENERATE_TEAMS_SITE_REQUEST,
  GENERATE_TEAMS_SITE_SUCCESS,
  GENERATE_TEAMS_SITE_ERROR,
  UPLOAD_FILE_TO_TEAMS_REQUEST,
  UPLOAD_FILE_TO_TEAMS_SUCCESS,
  UPLOAD_FILE_TO_TEAMS_ERROR,
  GET_TEAMS_TEMPLATE_SUCCESS,
  UPDATE_TEAMS_TENANT_ID,
  CHECK_TEAMS,
} from 'constants/teams-action-types';

import { fetchIncidents } from './profileActions';
import { toastConfig, noAutoClose } from 'assets/data/config';
import {
  removeSelfFromIncident,
  removeUserFromIncidentByAdmin,
} from './incidentActions';
import Network from 'PREPAREsrc/service/Network';
import apiConfig from 'constants/apiConfig';
import { updateIncident } from './diceActions';

export function generateTeamsSiteRequest(incident_id) {
  return {
    type: GENERATE_TEAMS_SITE_REQUEST,
    payload: incident_id,
  };
}

function generateTeamsSiteSuccess() {
  return {
    type: GENERATE_TEAMS_SITE_SUCCESS,
  };
}
export function updateTeamsTenants(tenant_Id) {
  return {
    type: UPDATE_TEAMS_TENANT_ID,
    payload: tenant_Id,
  };
}

function getTeamTemplateSuccess(templateList) {
  return {
    type: GET_TEAMS_TEMPLATE_SUCCESS,
    payload: templateList,
  };
}

function incrementTeamCheckCounter(count) {
  return {
    type: CHECK_TEAMS,
    payload: count,
  };
}

function generateTeamsSiteError(error) {
  const errorMessage =
    'generateTeamsSiteError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: GENERATE_TEAMS_SITE_ERROR,
  };
}

export function getTeamsTemplateError(error) {
  const errorMessage =
    'getTeamsTemplateError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
}

export const generateTeamsSite = (incident_id, template_id, tenantId) => {
  return (dispatch, getState) => {
    dispatch(generateTeamsSiteRequest(incident_id));
    axios(config.apiGateway.generateTeamSite, {
      method: 'POST',
      data: JSON.stringify({
        incident_id,
        templateId: template_id,
        tenantId: tenantId,
      }),
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(checkTeamsStatus(incident_id));
        }, 30000);
        toast.success('Creating Teams Site Started', toastConfig);
      })
      .catch((error) => {
        dispatch(generateTeamsSiteError(error));
      });
  };
};

export const generateWorkspaceTeamSite = (workspace_id, template_id, tenantId) => {
  return (dispatch) => {
    axios(config.apiGateway.generateTeamSite, {
      method: 'POST',
      data: JSON.stringify({
        workspace_id,
        templateId: template_id,
        tenantId: tenantId,
      }),
    })
      .then((response) => {
        toast.success('Creating Teams Site Started', toastConfig);
        return response
      })
      .catch((error) => {
        dispatch(generateTeamsSiteError(error));
      });
  };
};

export const checkTeamsStatus = (incident_id) => {
  return (dispatch, getState) => {
    const {
      checkTeamCounter,
      teamStatusFromIncident,
      incidents,
    } = getState().app;

    axios(config.apiGateway.checkTeamsStatus, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id ? incident_id : teamStatusFromIncident,
      }),
    })
      .then((response) => {
        if (!response.data?.create) {
          if (checkTeamCounter > 12) {
            const teamIncident = incidents.find(
              (i) => i.id === teamStatusFromIncident
            );
            if (teamIncident) {
              dispatch(
                updateIncident({
                  ...teamIncident,
                  team_status: 'unstarted',
                })
              );
            }
            dispatch(generateTeamsSiteSuccess());
          } else {
            setTimeout(() => {
              dispatch(
                checkTeamsStatus(
                  teamStatusFromIncident ? teamStatusFromIncident : incident_id
                )
              );
            }, 30000);
            dispatch(incrementTeamCheckCounter(checkTeamCounter + 1));
          }
        } else {
          toast.success('Teams Workspace created successfully', toastConfig);
          dispatch(fetchIncidents());
          dispatch(generateTeamsSiteSuccess());
        }
      })
      .catch((error) => {
        console.error('Error checking for team status:', error);
        if (checkTeamCounter > 10) {
          toast.error('Creating Teams Workspace Taking Time.', toastConfig);
          dispatch(fetchIncidents());
          dispatch(generateTeamsSiteSuccess());
        } else {
          dispatch(incrementTeamCheckCounter(checkTeamCounter + 1));
          setTimeout(() => {
            dispatch(checkTeamsStatus(teamStatusFromIncident));
          }, 60000);
        }
      });
  };
};

export const fetchTeamTemplates = (tenantId) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    return axios(config.apiGateway.teamTemplateIds, {
      method: 'POST',
      data: JSON.stringify({
        tenantId: tenantId,
      }),
    })
      .then((response) => {
        if (
          response.data?.message &&
          response.data.message === 'No template found'
        ) {
          dispatch(endLoading());
          toast.error('No Teams Saved Templates Found', toastConfig);
        }
        if (response?.data && response.data.length > 0) {
          let templateArray = response.data.map((template) => {
            return {
              label: template.name,
              value: template.id,
            };
          });
          dispatch(getTeamTemplateSuccess(templateArray, tenantId));
          dispatch(endLoading());
        }
      })
      .catch((error) => {
        console.log('fetchTeamTemplates error : ', error);
        dispatch(endLoading());
        dispatch(getTeamsTemplateError(error));
      });
  };
};

function uploadFileToTeamsRequest() {
  return {
    type: UPLOAD_FILE_TO_TEAMS_REQUEST,
  };
}

function uploadFileToTeamsSuccess() {
  return {
    type: UPLOAD_FILE_TO_TEAMS_SUCCESS,
  };
}

function uploadFileToTeamsError(error) {
  const errorMessage =
    'uploadFileToTeamsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPLOAD_FILE_TO_TEAMS_ERROR,
  };
}

export const uploadFileToTeams = ({
  tenant_Id,
  incident_id,
  file_name,
  teams_id,
  sharepoint_location,
  channel_id,
  form,
  formConfig,
}) => {
  return (dispatch, getState) => {
    dispatch(uploadFileToTeamsRequest());
    return axios(config.apiGateway.uploadFileToTeams, {
      method: 'POST',
      data: JSON.stringify({
        tenant_Id,
        incident_id,
        file_name,
        teams_id,
        sharepoint_location,
        channel_id,
        form,
        formConfig,
      }),
    })
      .then((response) => {
        const incident = response.data || {};
        toast.success(
          file_name + ' Shared with Teams successfully!',
          toastConfig
        );
        dispatch(uploadFileToTeamsSuccess(incident));
      })
      .catch((error) => {
        dispatch(uploadFileToTeamsError(error));
      });
  };
};

export const addMemberToTeams = (incident_id, membersEmail) => {
  return (dispatch, getState) => {
    const { userName } = getState().auth.account;
    const { featureFlags } = getState().app;
    return axios(config.apiGateway.addMemberToTeams, {
      method: 'POST',
      data: JSON.stringify({
        incident_id,
        userName,
        membersEmail,
      }),
    })
      .then((response) => {
        if (response.data.message === 'Member added to teams Successfully') {
          toast.success(
            'Member is successfully add to Team site.',
            toastConfig
          );
        }
      })
      .catch((error) => {
        console.log('addMemberToTeamSite error', error);
        toast.error('Something went wrong.', toastConfig);
      });
  };
};

export const RemoveUserFromTeamSite = (user, incidentId, deleteMemberData) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.removeMemberFromTeams, {
      method: 'POST',
      data: JSON.stringify({
        incidentId,
        userEmail: deleteMemberData.email_address,
      }),
    })
      .then((response) => {
        if (response.data.status !== 204 && response.data.status !== 404) {
          dispatch(endLoading());
          toast.error(response.data.message, toastConfig);
        }
        if (user === 'self') {
          dispatch(removeSelfFromIncident(deleteMemberData));
        } else {
          dispatch(removeUserFromIncidentByAdmin(deleteMemberData));
        }
      })
      .catch((error) => {
        console.log('removeMemberToTeamSite error', error);
        toast.error('Something went wrong.', toastConfig);
        if (error.status === 404 || error.status === 400) {

          if (error.status === 400) {
            toast.error(parseErrorMessage(error), toastConfig);
          }
          if (error.status === 404) {
            toast.error("The member was not found in Teams.", toastConfig);
          }
          if (user === 'self') {
            dispatch(removeSelfFromIncident(deleteMemberData));
          } else {
            dispatch(removeUserFromIncidentByAdmin(deleteMemberData));
          }
        }
        dispatch(endLoading());
      });
  };
};

import { useIncidentInfo } from './hooks/incidentInfoHooks';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { useToggle } from 'react-use';
import React from 'react';
import IncidentInfoEditDrawer from './IncidentInfoEditDrawer';

const IncidentSummary: React.FC = () => {
  const { data: incidentInfo } = useIncidentInfo();
  const [open, toggle] = useToggle(false);

  const defaultIncidentInfo = {
    id: "00000000-0000-0000-0000-000000000000", // placeholder UUID
    incident_id: "00000000-0000-0000-0000-000000000000", // placeholder UUID
    version: 1,
    timestamp: new Date(), // current date and time
    user_guid: "00000000-0000-0000-0000-000000000000", // placeholder UUID
    situation_summary: "",
    map_sketch_url: "",
    safety_plan_url: "",
    operational_period_command_emphasis: "",
    special_medical_emergency_procedures: "",
    medical_air_assets_yn: false
  };

  return (
    <>
      <div
        className="w-100 rounded rounded-1 bg-bgdark d-flex flex-column gap-3"
        style={{
          padding: '0.75rem 0.75rem 1rem 1rem',
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div> Situation Overview</div>
          <StylishNewButton onClick={toggle} className="button--secondary">
            Edit
          </StylishNewButton>
        </div>

        <div>{incidentInfo?.situation_summary}</div>
      </div>
      {open ? (
        <IncidentInfoEditDrawer toggle={toggle} incidentInfo={incidentInfo??defaultIncidentInfo} />
      ) : null}
    </>
  );
};

export default IncidentSummary;

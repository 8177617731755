import React, { useState, useEffect } from 'react';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useSitreps } from './hooks/useDisasterChats';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect'; // Assuming the select component is in the same directory
import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const DataSources = ({ selections = [], setSelections }) => {
  const { data: sitreps = [], error, isLoading, refetch } = useSitreps();

  const currentSitrepId = useSelector((state) => state.dchat.current_sitrep_id);

  const [dataSources, setDataSources] = useState([]);

  useEffect(() => {
    const fetchedDataSources = [
      { source: 'SITREP', description: 'Situation Reports', id: "" },
      { source: 'ICS-Active', description: 'Current ICS data.  Quick and concise.', id: "" },
      { source: 'ICS-Historic', description: 'Full version history of ICS data for current incident.  Expect longer run times. Precise prompts are advised.', id: "" },
      { source: 'Web', description: 'Web Scraping', id: "" },
      { source: 'News', description: 'News Articles', id: "" },
      { source: 'Social Media', description: 'Social Media Posts', id: "" }
    ];
    setDataSources(fetchedDataSources);
  }, []);

   const sitrepOptions = sitreps.map(sitrep => ({
    value: sitrep.id,
    label: sitrep.name,
  }));

  useEffect(() => {
    if (sitrepOptions.length > 0 && currentSitrepId) {
      const newSelections = selections.map(s => s.source === "SITREP" ? { ...s, id: currentSitrepId } : s);
      if (!newSelections.find(s => s.source === "SITREP")) {
        const newSitrepSelection = { ...dataSources.find(s => s.source === "SITREP"), id: currentSitrepId };
        setSelections([...newSelections, newSitrepSelection]);
      } else {
        setSelections(newSelections);
      }
    }
  }, [currentSitrepId, sitrepOptions]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row]);
    } else {
      setSelections(selections.filter(selection => selection.source !== row.source));
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelections(rows);
    } else {
      setSelections([]);
    }
  };

  const handleSitrepChange = (selectedOption) => {
    setSelections((prevSelections) => {
      const newSelections = prevSelections.filter(selection => selection.source !== 'SITREP');
      if (selectedOption) {
        newSelections.push({ source: 'SITREP', id: selectedOption.value });
      }

      setDataSources(prevDataSources =>
        prevDataSources.map(source =>
          source.source === 'SITREP'
            ? { ...source, id: selectedOption ? selectedOption.value : "" }
            : source
        )
      );

      return newSelections;
    });
  };


  const selectedSitrep = selections.find(selection => selection.source === 'SITREP');
  const selectedSitrepOption = selectedSitrep
    ? sitrepOptions.find(option => option.value === selectedSitrep.id)
    : null;

  const columns = [
    {
      dataField: 'source',
      text: 'Source Name',
      sort: true,
      attrs: { title: 'Source Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'data',
      text: 'Data',
      sort: false,
      formatter: (cellContent, row) => {
        if (row.source === 'SITREP') {

          if (!sitrepOptions.length) {
            return <div>No SITREPS Available</div>;
          }

          return (
            <StylishNewSelect
              options={sitrepOptions}
              value={selectedSitrepOption || null} // Ensure null is passed if no option is selected
              onChange={handleSitrepChange}
              isClearable={true}
              placeholder="Select a SITREP"
            />
          );
        }
        return null; // No additional data for other sources
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    hideSelectAll: false,
    selected: selections.map(selection => selection.source),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  return (
    <div>
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Data Sources</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <label>Select data for DisasterChat to consider:</label>
              <StylishNewTable
                key={`table-${sitrepOptions.length}-${selections.length}`}
                keyField={'source'}
                rows={dataSources}
                columns={columns}
                selectRow={selectRow}
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DataSources;

import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import {
  ResourceSite,
  useResourceSites,
} from './hooks/resourceSiteHooks';

import ResourceSiteAddDrawer from './ResourceSiteAddDrawer';
import ResourceSiteEditDrawer from './ResourceSiteEditDrawer';

import CollapsibleResourceSetsList from './CollapsibleResourceSetsList';

interface CollapsibleResourceSitesListProps {
  onMap?: boolean;
  flyTo?: any;
}

import {
  MdLocationOn,
  MdEdit,
} from 'react-icons/md';

const CollapsibleResourceSitesList: React.FC<CollapsibleResourceSitesListProps> = ({ onMap = false, flyTo }) => {
  const [expanded, toggleExpanded] = useToggle(onMap);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [selectedEntity, setSelectedEntity] = useState<ResourceSite | null>(
    null
  );

  const { data: resourceSites } = useResourceSites();

  const selectElement = (element: ResourceSite) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  return (
    <>
      <EntityList
        entities={resourceSites}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Resource Site (211, 213RR, 221, 238)</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <div key={element.id}>
            <Card
              className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
              style={{ padding: '0.625rem 1rem 0.625rem 1rem' }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>{element.name}</span>
                <div>
                  {!!onMap && (
                    <StylishNewButton
                      className="button button--link"
                      onClick={() => flyTo(element)}
                    >
                      <MdLocationOn />
                    </StylishNewButton>
                  )}
                  <StylishNewButton
                    className="button button--link"
                    onClick={() => selectElement(element)}
                  >
                    <MdEdit />
                  </StylishNewButton>
                </div>
              </div>
            </Card>
            <div style={{ marginLeft: '1.5rem' }}>
              <CollapsibleResourceSetsList onMap={onMap} flyTo={flyTo} resource_site_id={element.id} />
            </div>
          </div>
        )}
      ></EntityList>
      {addOpen && <ResourceSiteAddDrawer toggle={toggleAddDrawer} />}
      {editOpen && selectedEntity && (
        <ResourceSiteEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      )}
    </>
  );
};

export default CollapsibleResourceSitesList;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisasterChats } from './hooks/useDisasterChats';
import DisasterChatItem from './DisasterChatItem';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { setSelectedDChat, setCurrentSitrepId } from 'slices/dchatSlice';
import { IoMdBook } from 'react-icons/io';
import PromptLibraryList from './PromptLibraryList'; // Import the PromptLibraryList component
import { selectGroupGuid } from 'slices/commonSelectors';

const DisasterChatSidebar = ({ toggleDGPTOpen, DGPTOpen }) => {
    const dispatch = useDispatch();
    const { data: chats = [], isLoading } = useDisasterChats();
    const group_guid = useSelector(selectGroupGuid);

    const [showPromptLibrary, setShowPromptLibrary] = useState(false); // State to toggle between views

    const handleChatClick = (chat) => {
        const startedOpen = DGPTOpen;
        dispatch(setCurrentSitrepId());
        dispatch(setSelectedDChat(chat));
        toggleDGPTOpen();
        if (startedOpen) {
            toggleDGPTOpen();
        }
    };

    const togglePromptLibrary = () => {
        setShowPromptLibrary((prev) => !prev);
    };

    return (
        <div
            className="sidebar-content"
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }} // Added inline CSS here
        >
            <div className="sidebar-title">
                <h4 className="m-0">
                    {showPromptLibrary ? 'Prompt Library' : 'DisasterChats'}
                </h4>
            </div>
            <div className="new-chat-icon">
                {!showPromptLibrary && (
                    <StylishNewButton
                        className="button button--link"
                        onClick={() => handleChatClick({})}
                        style={{ margin: '8px' }}
                    >
                        <SharedIcon iconName="add" />
                    </StylishNewButton>
                )}
                <StylishNewButton
                    className="button button--link"
                    onClick={togglePromptLibrary}
                    style={{ margin: '8px', height: '28px', width: '28px' }}
                >
                    <IoMdBook />
                </StylishNewButton>
            </div>

            <hr className="dashed w-10 thin m-0" />

            {/* Added a wrapper div with overflowY scroll */}
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                {showPromptLibrary ? (
                    <PromptLibraryList group_guid={group_guid} />
                ) : isLoading ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>Loading...</span>
                    </div>
                ) : (
                    <ul
                        className="chat-list"
                        style={{ listStyleType: 'none', paddingLeft: '0', margin: '8px' }}
                    >
                        {chats.length > 0 ? (
                            chats.map((chat, i) => (
                                <div key={chat.dchat_id} style={{ marginTop: '8px' }}>
                                    <DisasterChatItem
                                        chat={chat}
                                        onClick={() => handleChatClick(chat)}
                                    />
                                    {i < chats.length - 1 && (
                                        <hr className="dashed w-10 thin m-0" />
                                    )}
                                </div>
                            ))
                        ) : (
                            <li>No chats available</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default DisasterChatSidebar;

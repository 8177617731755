import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';

// Hook to fetch SOP file vector store references
export const useSopFileRefs = (group_guid) => {
  return useQuery({
    queryKey: ['sopFileRefs', group_guid],
    queryFn: async () => {
      if (!group_guid) {
        throw new Error('group_guid is required');
      }
      
      console.log("Fetching SOP file refs for group_guid:", group_guid); // Debug log to see if the fetch is called
      const { data } = await axios.post(`${config.apiGateway.fetchSOPs}`, {
        group_guid,
      });
      return data;
    },
    enabled: !!group_guid,
    staleTime: 60000,
  });
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsContent from './NotificationsContent';
import {
  fetchAllDistributionList,
  fetchNotificationsByGroup,
} from 'actions/notificationActions';
import { fetchRostersForGroups } from 'actions/roleActions';

export default function NotificationsPage() {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchNotificationsByGroup(reduxCurrentlySelectedGroup.group_guid)
      );
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  return (
    <>
      <div className="site-main">
        <div className="site-main__notification">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <NotificationsContent
                  groupId={reduxCurrentlySelectedGroup.group_guid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { useCustomEvents, useCreateCustomEvent, useEditCustomEvent } from './hooks/customEventsHooks';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Card } from 'react-bootstrap';
import { useToggle } from 'react-use';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import EntityList from 'components/IAP/EntityList';
import { useForm, FormProvider } from 'react-hook-form';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import FormSelectField from 'components/IAP/formFields/FormSelectField';
import classNames from 'classnames';
import { MdEdit } from 'react-icons/md';
import { Offcanvas, Row } from 'react-bootstrap';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';  // Import GeoLocationComponent
import FormDateTimeField from 'components/IAP/formFields/FormDateTimeField';  // Import FormDateTimeField

const CustomEventsList = ({ group_guid, onSelectCustomEvent, selectedCustomEvent }) => {
  const { data: customEvents } = useCustomEvents(group_guid);
  const createCustomEvent = useCreateCustomEvent();
  const editCustomEvent = useEditCustomEvent();

  const [expanded, toggleExpanded] = useToggle(true);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [selectedEntity, setSelectedEntity] = useState(selectedCustomEvent);

  return (
    <>
      <EntityList
        entities={customEvents}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Custom Events</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              Add Event
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <Card
            key={element.id}
            className={`bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between 
              ${selectedEntity?.id === element.id ? 'selected' : ''}`} // Highlight selected row
            style={{ padding: '0.625rem 1rem', cursor: 'pointer' }} // Add cursor pointer and padding
            onClick={() => onSelectCustomEvent(element)} // Handle row click
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#333')} // Hover effect
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '')} // Remove hover effect
          >
            <span>{element.name}</span>
            <StylishNewButton
              className="button button--link"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering row click when clicking the edit button
                setSelectedEntity(element);
                toggleEditDrawer();
              }}
            >
              <MdEdit />
            </StylishNewButton>
          </Card>
        )}
      />

      {addOpen && <CustomEventDrawer toggle={toggleAddDrawer} onSubmit={createCustomEvent.mutate} />}
      {editOpen && selectedEntity && (
        <CustomEventDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
          onSubmit={(data) => editCustomEvent.mutate({ ...data })}
          toggleActive={() => {
            editCustomEvent.mutate({ ...selectedEntity, archived: !selectedEntity.archived });
            toggleEditDrawer();
          }}
        />
      )}
    </>
  );
};

const CustomEventDrawer = ({ toggle, selectedEntity, onSubmit, toggleActive }) => {
  const form = useForm({
    defaultValues: selectedEntity || {
      name: '',
      description: '',
      start_datetime: '',
      expected_end_datetime: '',
      actual_end_datetime: '',
      status: 'Planned',
      severity: 'Low',
      geolocation: '',  // Initialize geolocation as an empty string
    },
  });

  const { handleSubmit } = form;

  const [geolocation, setGeolocation] = useState(selectedEntity?.geolocation || '');  // Manage geolocation state

  const handleFormSubmit = (formData) => {
    const dataToSubmit = {
      ...formData,
      start_datetime: formData.start_datetime === '' ? null : formData.start_datetime,
      expected_end_datetime: formData.expected_end_datetime === '' ? null : formData.expected_end_datetime,
      actual_end_datetime: formData.actual_end_datetime === '' ? null : formData.actual_end_datetime,
      geolocation: geolocation === '' ? null : geolocation,  // Use geolocation from state
      id: selectedEntity?.id,
    };

    onSubmit(dataToSubmit);
    toggle(); // Close the drawer after submission
  };

  return (
    <DrawerWrapper toggle={toggle} title={selectedEntity ? 'Edit Event' : 'Add New Event'}>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <FormTextField
              title="Event Name"
              fieldName="name"
              rules={{
                required: { value: true, message: 'This field is required' },
                maxLength: { value: 255, message: 'The maximum length is 255 characters' },
              }}
            />
            <FormTextAreaField
              title="Description"
              fieldName="description"
              rules={{ maxLength: { value: 500, message: 'The maximum length is 500 characters' } }}
            />
            <FormDateTimeField
              title="Start Date"
              fieldName="start_datetime"
              variant="half"
              type="datetime-local"
            />
            <FormDateTimeField
              title="Expected End Date"
              fieldName="expected_end_datetime"
              variant="half"
              type="datetime-local"
            />
            <FormDateTimeField
              title="Actual End Date"
              fieldName="actual_end_datetime"
              variant="half"
              type="datetime-local"
            />
            <FormSelectField
              title="Status"
              fieldName="status"
              options={[
                { label: 'Planned', value: 'Planned' },
                { label: 'Current', value: 'Current' },
                { label: 'Past', value: 'Past' },
              ]}
            />
            <FormSelectField
              title="Severity"
              fieldName="severity"
              options={[
                { label: 'Low', value: 'Low' },
                { label: 'Medium', value: 'Medium' },
                { label: 'High', value: 'High' },
              ]}
            />
            <Row>
              <div className="m-0">
                <label className="form-label">Geolocation:</label>
                <GeoLocationComponent location={geolocation} setLocation={setGeolocation} />
              </div>
            </Row>
          </Offcanvas.Body>

          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {selectedEntity?.archived ? 'Activate' : 'Deactivate'}
              </StylishNewButton>
              <StylishNewButton className="button--secondary" type="button" onClick={toggle}>
                Close
              </StylishNewButton>
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default CustomEventsList;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentIncidentById } from 'actions/diceActions';
import { useNavigate } from 'react-router-dom';
import {
  getAllInitialIncidentReportRecipientList,
  updateInitialIncidentReportRecipientList,
} from 'actions/ReportActions';
import { fetchIncidentsForGroup } from 'actions/roleActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import NewIncidentDialog from 'components/IncidentConfig/NewIncidentDialog'

export default function GroupIncidents() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const reduxIncidentsForGroup = useSelector((state) => {
    const incidents = state.app.incidentsForGroup;
    return incidents;
  });
  const allInitialIncidentReportRecipientList = useSelector((state) => {
    return state.app.allInitialIncidentReportRecipientList;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const [rostersForGroups, setRostersForGroups] = useState([]);
  const [
    selectedInitialIncidentReportRecipientList,
    setSelectedInitialIncidentReportRecipientList,
  ] = useState([]);

  const [newIncidentDialogActive, setNewIncidentDialogActive] = useState(false)

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchIncidentsForGroup(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup,newIncidentDialogActive]);

  useEffect(() => {
    if (reduxRostersForGroups.length && reduxCurrentlySelectedGroup) {
      const selectedRecipientList = [];
      const collection = reduxRostersForGroups
        .filter(
          (member) =>
            member.group_guid === reduxCurrentlySelectedGroup.group_guid
        )
        .map((member) => {
          const prevReturnValue = {
            ...member,
            role_assignments_string: member.role_assignments
              .map((ra) => {
                return ra.name;
              })
              .join(', '),
            ...(member.seats
              ? {
                  seat_assignments_string: member.seats
                    .map((seat) => seat.seat_name)
                    .join(','),
                }
              : {}),
            tags_string: member.role_assignments.map((ra) => {
              return ra.tags
                .map((tag) => {
                  return tag.name;
                })
                .join(', ');
            }),
          };
          if (
            !!allInitialIncidentReportRecipientList &&
            allInitialIncidentReportRecipientList.length > 0
          ) {
            const recipientList = allInitialIncidentReportRecipientList.find(
              (item) => item.user_guid === member.user_guid
            );
            if (recipientList) {
              selectedRecipientList.push(prevReturnValue);
            }
          }
          return Object.assign({}, prevReturnValue);
        });
      setSelectedInitialIncidentReportRecipientList(selectedRecipientList);
      setRostersForGroups(collection);
      reduxDispatch(endLoading());
    }
  }, [
    reduxRostersForGroups,
    reduxCurrentlySelectedGroup,
    allInitialIncidentReportRecipientList,
  ]);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'types',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'severity',
      text: 'Severity',
      sort: true,
      attrs: { title: 'Severity' },
    },
  ];

  const handleIncidentLinkClick = (incidentId) => {
    reduxDispatch(startLoading());
    reduxDispatch(updateCurrentIncidentById(incidentId));
    navigate('/incident_configuration');
  };

  function onNewIncidentDialogClose()
  {
    reduxDispatch(fetchIncidentsForGroup(reduxCurrentlySelectedGroup.group_guid))
    setNewIncidentDialogActive(false)
  }

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <StylishNewButton
            className="button--primary w-100 w-md-auto"
            type="button"
            onClick={() => handleIncidentLinkClick(row.incident_id)}
          >
            Configure Incident
          </StylishNewButton>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
    <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Organization Incidents</h4>
        <StylishNewButton
          className="button--primary mt-2 mt-md-0 ms-auto"
          onClick={()=>setNewIncidentDialogActive(true)}
        >
          New Incident
        </StylishNewButton>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'incident_id'}
          columns={columns}
          rows={
            reduxIncidentsForGroup && !!reduxIncidentsForGroup.length
              ? reduxIncidentsForGroup.map((rig) => ({
                  ...rig,
                  types: rig?.incident_types?.join(', '),
                }))
              : []
          }
          expandRow={expandRow}
        />
      </div>
      {newIncidentDialogActive && (
        <NewIncidentDialog
          show={newIncidentDialogActive}
          onClose={onNewIncidentDialogClose}
        />
      )}
    </>
  );
}

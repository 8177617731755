export const chatDGPTSessionsSchema = [
	{
		// UUID
		dgpt_session_id: "string",
		// 'event' | 'aor' | 'chat'
		functions: {

		},
		// Object: event | aor | {}
		initSessionInfo: {},
		locationInfo: {},
		// Array of GPT Message Completions
		messages: [],
		centroid: {},
		weatherCurrent: {},
		weatherForecast: {},
		bingNews: {},
		events: [],
		powerOutages: [],
		powerOutagePredictions: [],
		socialMediaPosts: {
			"twitter": {
				"posts": [],
				"analysis": "string"
			}
		}
	}
]

export const sitrepDataSources = [
  'EVENTS',
  'CURRENT WEATHER',
  'FORECAST WEATHER',
  'POWER OUTAGES',
  'POWER OUTAGE PREDICTIONS',
  'NEWS',
  'SOCIAL MEDIA',
]


export const tenantId = '1ed96d38-7385-4a96-8d28-d5bc7da9c92a'
export const sharepointDriveId = 'b!oV3olmVTrU-SMkl5-fh8BjfvpRkdaxNNsK3ls1QHczHHiAxDcGvkQIAbwg1Mym8_'

import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useSharepointFiles } from './hooks/useSharepointFiles'; // Import the hook to fetch SharePoint files

const SharepointFiles = ({ selections = [], setSelections }) => {
  const { data: files = [], isLoading } = useSharepointFiles(); // Fetch SharePoint files
  const [fileToUpload, setFileToUpload] = useState(null);
  
  // Define handleSelect to manage selection of full row
  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row]); // Add the full row object
    } else {
      setSelections(selections.filter(selection => selection.id !== row.id)); // Filter by id to remove
    }
  };

  // Define handleSelectAll to manage selection of all rows with full row data
  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelections(rows); // Select all rows
    } else {
      setSelections([]); // Deselect all rows
    }
  };

  const onFileChange = event => {
    setFileToUpload(event.target.files[0]);
  };

  const onFileUpload = () => {
    // Implement file upload logic here
    console.log('File uploaded:', fileToUpload);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'File Name',
      sort: true,
      attrs: { title: 'File Name' },
    },
    {
      dataField: 'file.mimeType',
      text: 'File Type',
      sort: true,
      attrs: { title: 'File Type' },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selections.map(selection => selection.id), // Only compare by id for checkbox
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  return (
    <div className="SharepointFiles">
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Teams/SharePoint Documents</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <h4>Select Documents in your Teams SharePoint to optimize DisasterChat.</h4>
              <h5>
                .xlsx is currently not supported. In Excel, "Save As" PDF, then
                upload.
              </h5>
              <div>
                {isLoading && (
                  <div>Loading...</div>
                )}
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <StylishNewTable
                  keyField={'id'}
                  rows={files}
                  columns={columns}
                  selectRow={selectRow}
                />
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SharepointFiles;

import { Card } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { useDispatch } from 'react-redux';
import { exportAsForm } from '../../actions/formActions';

type FormExportCardProps = {
  formName: string;
  label: string;
};

const FormExportCard: React.FC<FormExportCardProps> = ({ formName, label }) => {
  const dispatch = useDispatch();

  return (
    <Card className="bg-bgdark m-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>{label}</div>
          <StylishNewButton
            className="button--secondary"
            onClick={() => dispatch(exportAsForm({ formname: formName }))}
          >
            Export
          </StylishNewButton>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FormExportCard;

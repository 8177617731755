import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import Dropdown from 'react-bootstrap/Dropdown';
import Info from 'components/DesignSystems/text/Info';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useNavigate } from 'react-router-dom';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import {
  dgptSitrepToInitialIncidentReportSummary,
  exportSitrepToPdf,
  refreshSitrep,
  addSitrepSection,
} from 'actions/dgptActions';
import { sitrepDataSources } from './DisasterGPTConstants';
import SitrepSection from './SitrepSection';
import { setCurrentSitrepId, setSelectedDChat } from 'slices/dchatSlice';

function sortCategories(data, categories) {
  return data.sort((a, b) => {
    const indexA = categories.indexOf(
      a.section_versions[0].sitrep_section_title
    );
    const indexB = categories.indexOf(
      b.section_versions[0].sitrep_section_title
    );

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA === -1 && indexB !== -1) {
      return 1;
    }

    if (indexA !== -1 && indexB === -1) {
      return -1;
    }

    return 0;
  });
}

const Sitrep = ({ sitrep, map, toggleDGPTOpen, name, selectedDatetime }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const divRef = useRef();

  const [input, setInput] = useState('');
  const [createReport, setCreateReport] = useState(false);
  const [showMostRecent, setShowMostRecent] = useState(true);
  const [addingSection, setAddingSection] = useState(false);
  const [addSectionTitle, setAddSectionTitle] = useState('');

  const isFetchChatDGPTLoaded = useSelector((state) => state.app.isFetchChatDGPTLoaded);
  const dgptSitrepToInitialIncidentReport = useSelector((state) => state.app.dgptSitrepToInitialIncidentReport);
  const isRefreshSitrepLoaded = useSelector((state) => state.app.isRefreshSitrepLoaded);
  const isFetchSitrepsLoaded = useSelector((state) => state.app.isFetchSitrepsLoaded);
  const reduxFeatureFlags = useSelector((state) => state.app.featureFlags);
  const currentSitrepId = useSelector((state) => state.dchat.current_sitrep_id);

  function cleanupReturnMsg(returnMsg) {
    let finalReturnMsg = returnMsg.replaceAll('```json', '').replaceAll('```', '');
    try {
      finalReturnMsg = JSON.parse(finalReturnMsg);
    } catch (e) {
      console.error('cleanupReturnMsg ERR:', e);
      console.error('MSG: ', returnMsg);
    }
    return finalReturnMsg;
  }

  function prepareMessagesForReport(sitrep) {
    const sorted = sortCategories(sitrep.sections, sitrepDataSources);
    let pattern = /【[^】]*】/g;
    const markdownString = sorted
      .map((s) => {
        let title = s.section_versions[0].sitrep_section_title;
        let text = s.section_versions[0].sitrep_section_text;

        text = text?.replaceAll('```json', '').replaceAll('```', '');
        if (text && text[0] === `"` && text[text.length - 1] === `"`) {
          text = text.slice(1, -1);
        }

        return `
### ${title}:  

${text}

---
`
          .replaceAll('{{', '')
          .replaceAll('}}', '');
      })
      .map((line) => line.trim())
      .join('\n')
      .replaceAll(pattern, '')
    return markdownString;
  }

  function sendDgptSitrepToInitialIncidentReportSummary() {
    setCreateReport(true);

    const sitrepMarkdown = prepareMessagesForReport(sitrep);

    const canvas = map.current.getCanvas();
    const mapScreenshot = canvas.toDataURL('image/png');

    reduxDispatch(
      dgptSitrepToInitialIncidentReportSummary({
        sitrep: sitrep,
        sitrep_id: sitrep.id,
        sitrepMarkdown: sitrepMarkdown,
        reference: sitrep.sitrep_source,
        reference_id: sitrep.sitrep_source_id,
        mapScreenshot: mapScreenshot,
      })
    );
  }

  function exportDGPTSessionAsPDF() {
    const sitrepMarkdown = prepareMessagesForReport(sitrep);
    reduxDispatch(
      exportSitrepToPdf({ sitrepMarkdown, sitrepId: sitrep.id, output: 'PDF' })
    );
  }

  function exportDGPTSessionAsWord() {
    const sitrepMarkdown = prepareMessagesForReport(sitrep);
    reduxDispatch(
      exportSitrepToPdf({ sitrepMarkdown, sitrepId: sitrep.id, output: 'DOCX' })
    );
  }

  function timestampSelectedCallback() {
    setShowMostRecent(false);
  }

  useEffect(() => {
    if (!!dgptSitrepToInitialIncidentReport && !!createReport) {
      setCreateReport(false);
      navigate(`/report/new`, { replace: true });
    }
  }, [dgptSitrepToInitialIncidentReport, createReport]);

  function updateSitrep() {
    reduxDispatch(refreshSitrep({ id: sitrep.id, name: name, selectedDatetime }));
  }
  function addSectionClicked() {
    setAddingSection(true);
  }

  useEffect(() => {
    if (addingSection) {
      const { current } = divRef;
      if (current !== null) {
        current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [addingSection]);

  function addSectionSaveClicked() {
    const sectionId = generateUUID();
    reduxDispatch(
      addSitrepSection({
        sitrepId: sitrep.id,
        sectionId,
        sectionTitle: addSectionTitle,
        selectedDatetime: selectedDatetime
      })
    );
    setAddingSection(false);
  }

  function addSectionCancelClicked() {
    setAddingSection(false);
    setAddSectionTitle('');
  }

  const sortedSections = sortCategories(sitrep.sections, sitrepDataSources);

  function chatClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId(sitrep.id));
    toggleDGPTOpen();
  }

  function prepareSitrepForClipboard() {
    const sorted = sortCategories(sitrep.sections, sitrepDataSources);
    return sorted.map((section) => {
      let title = section.section_versions[0].sitrep_section_title;
      let text = section.section_versions[0].sitrep_section_text;

      return `
**${title}:**  

${text}
`;
    }).join('\n');
  }

  function copySitrepToClipboard() {
    const sitrepContent = prepareSitrepForClipboard();
    navigator.clipboard.writeText(sitrepContent).catch(err => {
      console.error('Failed to copy SITREP: ', err);
    });
  }

  return (
    <div className="pb-5">
      <div>
        {!!sitrep.name && (<h5>{sitrep?.name}</h5>)}
      </div>
      {!!sitrep &&
        !!sitrep.sections &&
        sitrep.sections.map((section, index) => {
          return (
            <SitrepSection
              key={'SitrepSection-' + index}
              sitrep={sitrep}
              section={section}
              timestampSelectedCallback={timestampSelectedCallback}
              showMostRecent={showMostRecent}
              isFetchSitrepsLoaded={isFetchSitrepsLoaded}
            />
          );
        })}
      {!!addingSection ? (
        <div className="SitrepSection mb-5" ref={divRef}>
          <div className="paragraph-header">
            <h4>Add Section</h4>
          </div>
          <div className="SitrepSection-text">
            <StylishNewTextArea
              type="text"
              className="text-no-hover"
              value={addSectionTitle}
              disabled={!isFetchSitrepsLoaded}
              onChange={(e) => setAddSectionTitle(e.target.value)}
              onKeyPress={(e) =>
                e.key === 'Enter' ? addSectionSaveClicked() : null
              }
              placeholder={
                'DisasterGPT will search for information based on your Section title'
              }
            />
            <div className="mt-2 d-flex justify-content-end p-3">
              <StylishNewButton
                className="button--secondary me-3"
                onClick={() => addSectionCancelClicked()}
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                onClick={() => addSectionSaveClicked()}
              >
                Save
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="add-section" onClick={() => addSectionClicked()}>
          <span className="material-symbols-outlined me-2 fs-5">add</span> Add
          Section
        </div>
      )}
      {(!isFetchSitrepsLoaded || !isRefreshSitrepLoaded) && (
        <div className="pb-5 text-center">
          <span>DisasterGPT is analyzing...</span>
          <div>
            <i className="fa fa-spinner fa-pulse fs-6"></i>
          </div>
          <Info>AutoSITREP can take between 10-60 seconds to complete.</Info>
        </div>
      )}
      {!addingSection && (
        <div className="tab-footer">
          {((!isRefreshSitrepLoaded || !isFetchSitrepsLoaded) && (
            <div>
              <i className="fa fa-spinner fa-pulse me-2"></i>Updating Sitrep...
            </div>
          )) || <></>}
          <div>
            <StylishNewButton
              className="button--secondary me-3"
              onClick={chatClicked}
              disabled={!isFetchSitrepsLoaded || !isRefreshSitrepLoaded}
            >
              <span className="material-symbols-outlined">chat</span>DisasterChat
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary me-3"
              onClick={updateSitrep}
            >
              <span className="material-symbols-outlined">refresh</span>Refresh
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary me-3"
              onClick={copySitrepToClipboard}
              disabled={!isFetchSitrepsLoaded || !isRefreshSitrepLoaded}
            >
              Copy to Clipboard
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              onClick={sendDgptSitrepToInitialIncidentReportSummary}
              disabled={!isFetchSitrepsLoaded || !isRefreshSitrepLoaded}
            >
              <span className="material-symbols-outlined">electric_bolt</span>
              Create Incident Report
            </StylishNewButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sitrep;

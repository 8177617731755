import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IncidentConfig from 'components/IncidentConfig/IncidentConfig';
import {
  updateCurrentIncident,
  updateIncidentGroup,
} from 'actions/diceActions';
import { fetchTeamTemplates, generateTeamsSite } from 'actions/teamsActions';

import CloneDefaults from 'components/CloneDefaults/CloneDefaults';
import IncidentAdminToolbar from 'components/incidentAdminToolbar/IncidentAdminToolbar';
import useGroupAdminForCurrentIncident from 'hooks/useGroupAdminForCurrentIncident';
import ManageIncidentObjectives from './ManageIncidentObjectives';
import ManageIncidentResources from './ManageIncidentResources';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import NewIncidentDialog from './NewIncidentDialog';
import EditOperationalPeriodDialog from './EditOperationalPeriodDialog';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { startLoading } from 'reducers/loading/loading.action';

import validateSubscription from 'utils/validateSubscription';
import { fetchIncidentStatus } from 'actions/profileActions';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import PageTitle from 'components/headers/PageTitle';
import { TemplateWizard } from 'components/TemplateWizard/TemplateWizard';
import { IconContainerWithButton } from 'components/DesignSystems/IconContainerWithButton/IconContainerWithButton';
import { ExistingTemplates } from 'components/TemplateWizard/ExistingTemplates';

export default function IncidentConfigureTab() {
  const reduxDispatch = useDispatch();

  const navigate = useNavigate();
  const userIsGroupAdmin = useGroupAdminForCurrentIncident();
  const [selected, setSelected] = useState();
  const [saveRequested, setSaveRequested] = useState(false);
  const [updateIncidentTypeLoaded, setUpdateIncidentTypeLoaded] = useState(
    true
  );
  const [isNewIncidentOpen, setIsNewIncidentOpen] = useState(false);
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [updateSeverityLoaded, setUpdateSeverityLoaded] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Roster');
  const [currentGroup, setCurrentGroup] = useState(null);
  const [
    showSelectTeamsTemplateDialog,
    setShowSelectTeamsTemplateDialog,
  ] = useState(false);

  const toggleData = [
    { label: 'Roster', value: 'Roster' },
    { label: 'Objectives', value: 'Objectives' },
    { label: 'Resources', value: 'Resources' },
  ];

  const user = useSelector((state) => {
    return state.app.user;
  });
  const tenantId = useSelector((state) => {
    return state.app.tenantId;
  });

  const teamsTemplates = useSelector((state) => {
    return state.app.teamsTemplates;
  });
  const reduxUserSubscriptionInfo = useSelector((state) => {
    return state.app.userSubscriptionInfo;
  });

  const { id: incidentGuid, group_guid: groupGuid } =
    useSelector((state) => {
      return state.app.currentIncident;
    }) || {};

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const isSaveLoaded = useSelector((state) => {
    return state.app.isUpdateIncidentGroupLoaded;
  });

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  const generateTeamSite = featureFlags.includes('GENERATE_TEAM_SITE');

  const groups = useSelector((state) => {
    const rawGroups = state.app.groups || [];
    return rawGroups
      .map((group) => ({
        groupGuid: group.group_guid,
        nameOfGroup: group.group_name,
        isCurrentUserPending: (!!(group.role === 'Pending')).toString(),
      }))
      .filter(({ isCurrentUserPending }) => isCurrentUserPending !== 'true')
      .map((group) => ({ label: group.nameOfGroup, value: group.groupGuid }));
  });

  const reduxGroups = useSelector((state) => {
    return state.app.groups;
  });

  const currentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const isUpdateIncidentLoaded = useSelector((state) => {
    return state.app.isUpdateIncidentLoaded;
  });
  const isGenerateTeamsSiteLoaded = useSelector((state) => {
    return state.app.isGenerateTeamsSiteLoaded;
  });

  useEffect(() => {
    if (!updateIncidentTypeLoaded && isUpdateIncidentLoaded) {
      setUpdateIncidentTypeLoaded(true);
    }
    if (!updateSeverityLoaded && isUpdateIncidentLoaded) {
      setUpdateSeverityLoaded(true);
    }
  }, [isUpdateIncidentLoaded, updateIncidentTypeLoaded, updateSeverityLoaded]);

  useEffect(() => {
    if (groupGuid) {
      setSelected(groupGuid);
    }
  }, [groupGuid]);

  useEffect(() => {
    if (isSaveLoaded && saveRequested) {
      setSaveRequested(false);
    }
  }, [isSaveLoaded, saveRequested]);

  useEffect(() => {
    if (!!reduxCurrentGroup) {
      setCurrentGroup({
        label: reduxCurrentGroup.group_name,
        value: reduxCurrentGroup.group_guid,
      });
    }
  }, [reduxCurrentGroup]);

  useEffect(() => {
    if (!!currentIncident) {
      if (
        currentIncident?.team_status !== 'unstarted' &&
        showSelectTeamsTemplateDialog
      ) {
        setShowSelectTeamsTemplateDialog(false);
        toast.warning('A team site is already created.', toastConfig);
      }
    }
  }, [currentIncident]);

  const onClickGenerateTeamsSite = async (incident_id) => {
    await reduxDispatch(fetchIncidentStatus(incident_id));
    if (currentIncident?.team_status === 'unstarted') {
      setShowSelectTeamsTemplateDialog(true);
      if (teamsTemplates.length == 0) {
        reduxDispatch(startLoading());
        reduxDispatch(fetchTeamTemplates(tenantId));
      }
    }
  };

  const handleSaveClick = () => {
    setSaveRequested(true);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentGroup({
        incidentGuid,
        groupGuid: currentGroup.value,
      })
    );
  };

  const handleDropdownChange = (data) => {
    setCurrentGroup({ label: data.label, value: data.value });
  };

  const goToFormBuilder = () => {
    navigate('/incident_management/ICS_201-CG');
  };

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  const generateTeamsSiteClicked = (template_id) => {
    reduxDispatch(
      updateCurrentIncident({ ...currentIncident, team_status: 'inprogress' })
    );
    reduxDispatch(
      generateTeamsSite(currentIncident?.id, template_id, tenantId)
    );
    setShowSelectTeamsTemplateDialog(false);
  };

  let subscriptionValidated = true;
  if (
    featureFlags.includes('STRIPE') &&
    !validateSubscription(user, reduxUserSubscriptionInfo)
  ) {
    subscriptionValidated = false;
  }

  const StepOneComponent = ({ handleTemplateSelect }) => (
    <IconContainerWithButton
      buttonText="Use Existing"
      description="Implement existing Teams templates to expedite setup, leveraging established structures for quick operational integration."
      title="Use Existing Templates"
      onButtonClick={() => handleTemplateSelect()}
      icon="file_copy"
    />
  );

  const StepTwoComponent = ({ handleTemplateSelect }) => (
    <ExistingTemplates handleTemplateSelect={handleTemplateSelect} />
  );

  const StepThreeComponent = ({ selectedTemplate }) => (
    <div className="w-100 d-flex flex-column align-items-start justify-content-between">
      <div className="w-100 d-flex flex-row justify-content-between">
        <span className="label">Template Name</span>
        <span>{selectedTemplate?.label}</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="site-main">
        <div className="site-main__organization-configuration">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <PageTitle title="Incident Workspace" />

                <div className="mb-4">
                  <div className="d-lg-flex">
                    <div className="button-group d-block d-md-flex">
                      <StylishNewButton
                        className="button--primary w-100 w-md-auto"
                        disabled={
                          !reduxValidateRBACPermissionForActionResult[
                            'Create Group Incident'
                          ]
                        }
                        onClick={() => setIsNewIncidentOpen(true)}
                      >
                        New Incident
                      </StylishNewButton>
                      {!!reduxCurrentIncident && (
                        <StylishNewButton
                          className="button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                          disabled={
                            !reduxValidateRBACPermissionForActionResult[
                              'Create Group Incident'
                            ]
                          }
                          onClick={() => setIsEditOperationalPeriodOpen(true)}
                        >
                          Edit Operational Period
                        </StylishNewButton>
                      )}
                      {!!tenantId &&
                        !!generateTeamSite &&
                        !!subscriptionValidated && (
                          <>
                            {(!!currentIncident &&
                              !currentIncident?.teams_id &&
                              !currentIncident?.channel_id &&
                              !currentIncident?.sharepoint_location &&
                              currentIncident?.team_status === 'unstarted' && (
                                <>
                                  <StylishNewButton
                                    className="button--primary"
                                    onClick={() =>
                                      onClickGenerateTeamsSite(
                                        currentIncident?.id
                                      )
                                    }
                                  >
                                    Generate Teams Site
                                  </StylishNewButton>
                                  {showSelectTeamsTemplateDialog && (
                                    <TemplateWizard
                                      steps={[
                                        {
                                          id: 1,
                                          label: 'Select Starting Point',
                                          component: StepOneComponent,
                                          pageTitle: 'Select Starting Point',
                                          pageSubTitle:
                                            'You can start from scratch or build upon a pre-designed template.',
                                        },
                                        {
                                          id: 2,
                                          label: 'Choose a template',
                                          pageTitle:
                                            'Choose from Existing Templates',
                                          pageSubTitle:
                                            'Select from existing Teams or PRATUS templates to get started',
                                          component: StepTwoComponent,
                                        },
                                        {
                                          id: 3,
                                          label: 'Launch',
                                          pageTitle: 'Launch Your Template',
                                          component: StepThreeComponent,
                                        },
                                      ]}
                                      breadcrumbs={[
                                        {
                                          id: 1,
                                          label: 'Select Starting Point',
                                          isActive: false,
                                        },
                                        {
                                          id: 2,
                                          label: 'Use Existing Templates',
                                          isActive: true,
                                        },
                                      ]}
                                      onSubmit={(template) => {
                                        generateTeamsSiteClicked(
                                          template.value
                                        );
                                      }}
                                      onModalClose={() =>
                                        setShowSelectTeamsTemplateDialog(false)
                                      }
                                    />
                                  )}
                                </>
                              )) ||
                              (currentIncident?.team_status ===
                                'inprogress' && (
                                <div style={{ marginLeft: '16px' }}>
                                  Creating Teams Workspace...
                                </div>
                              )) ||
                              (currentIncident?.team_status === 'done' && (
                                <div style={{ marginLeft: '16px' }}>
                                  Teams Workspace Created!
                                </div>
                              ))}
                          </>
                        )}
                      {!subscriptionValidated && (
                        <div>Pratus Premium required for Teams Integration</div>
                      )}
                    </div>
                    <StylishNewToggleButtonGroup
                      data={toggleData}
                      selected={selectedTab}
                      onChange={handleToggleButtonClick}
                      className="ms-auto mt-4 mt-lg-0 w-100 w-lg-auto"
                    />
                  </div>
                  {!reduxValidateRBACPermissionForActionResult[
                    'Create Group Incident'
                  ] && (
                    <span className="form-text form-error mt-3">
                      You do not have permission to create new Incident &amp;
                      edit Operational Period
                    </span>
                  )}
                </div>
                <div className="row">
                  <div className="col-xl-3 order-1 order-xl-0">
                    <IncidentAdminToolbar />
                    {!!currentIncident && (
                      <>
                        <div className="bg-gray-800 rounded p-3 p-md-4 mt-4">
                          <h6 className="text-uppercase mt-0">
                            Sponsor Organization
                          </h6>
                          <hr className="dashed my-3" />
                          <span className="d-block txt--sml mb-3">
                            ** You must have the proper permissions for the
                            incident and sponsor organization to make changes
                          </span>
                          {groups && groups.length > 0 && (
                            <>
                              <StylishNewSelect
                                options={groups}
                                value={currentGroup}
                                placeholder="Select a Type"
                                onChange={(e) => handleDropdownChange(e)}
                                isClearable={false}
                                isSearchable={false}
                                isMulti={false}
                                isDisabled={
                                  saveRequested ||
                                  !(
                                    currentIncident?.role === 'Admin' ||
                                    userIsGroupAdmin
                                  )
                                }
                              />
                            </>
                          )}
                          <div className="button-group flex-wrap justify-content-end mt-4">
                            <StylishNewButton
                              disabled={
                                saveRequested ||
                                !(
                                  currentIncident?.role === 'Admin' ||
                                  userIsGroupAdmin
                                )
                              }
                              className="button--secondary"
                              onClick={() => handleSaveClick()}
                            >
                              Save
                            </StylishNewButton>
                            {saveRequested ||
                              (!(
                                currentIncident?.role === 'Admin' ||
                                userIsGroupAdmin
                              ) && (
                                <span className="form-text form-error multiline">
                                  Only Organization Admins can change the
                                  Organization assignment.
                                </span>
                              ))}
                          </div>
                        </div>
                        {/** TODO: ask @dtinikko if Clone Defaults is still a supported feature. */}
                        <CloneDefaults />
                      </>
                    )}
                  </div>
                  {!!reduxCurrentIncident && (
                    <>
                      {!!currentIncident && (
                        <>
                          <div className="col-xl-9 mb-4 mb-xl-0">
                            {selectedTab === 'Roster' && <IncidentConfig />}
                            {selectedTab === 'Objectives' && (
                              <ManageIncidentObjectives />
                            )}
                            {selectedTab === 'Resources' && (
                              <ManageIncidentResources />
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isNewIncidentOpen && (
        <NewIncidentDialog
          show={isNewIncidentOpen}
          onClose={() => setIsNewIncidentOpen(false)}
        />
      )}
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
    </>
  );
}

import {
  selectUserGuid,
  useAppSelector,
} from '../../../slices/commonSelectors';
import { useActualIncidentId } from './commonHooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toastConfig } from '../../../assets/data/config';
import { toast } from 'react-toastify';

export type IncidentInfo = {
  id: UUID;
  incident_id: UUID;
  version: number;
  timestamp: Date;
  user_guid: UUID;
  situation_summary: string;
  map_sketch_url: string;
  safety_plan_url: string;
  operational_period_command_emphasis: string;
  special_medical_emergency_procedures: string;
  medical_air_assets_yn: boolean;
};

export const keys = {
  base: 'incident_info',
  get all() {
    return [keys.base];
  },
  get: (incidentId: UUID) => [keys.base, incidentId],
};

export const useIncidentInfo = () => {
  const incidentId = useActualIncidentId();
  return useQuery({
    queryKey: keys.get(incidentId),
    queryFn: async () => {
      const resp = await axios.get<IncidentInfo>(
        `${config.apiGateway.ics_incident_info}/${incidentId}`
      );
      return resp.data;
    },
  });
};

type EditIncidentInfoReqData = Partial<
  Omit<
    IncidentInfo,
    'id' | 'incident_id' | 'user_guid' | 'version' | 'timestamp'
  >
>;

export const updateIcsInfo = () => {
  const user_guid = useAppSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentInfoReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_incident_info}/${incidentId}`,
        data,
        {
          headers: { user_guid },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keys.get(incidentId),
      });
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};

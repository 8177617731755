import { Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SelectIncidentModal from './SelectIncidentModal';

const IAPWrapper = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!incidentId || incidentId === 'undefined') {
      setModal(true);
    } else if (modal && incidentId) {
      setModal(false);
    }
  }, [incidentId]);

  return modal ? <SelectIncidentModal /> : <Outlet />;
};

export default IAPWrapper;

import React, { createContext, useEffect, useState } from 'react';
import { changeFeatureFlags } from './actions/featureFlagActions';
import { useDispatch, useSelector } from 'react-redux';

const themes: Record<string, any> = {};

const Context = createContext<{
  theme: 'legacy' | 'pratus' | null;
  toggleTheme: () => void;
}>({
  theme: null,
  toggleTheme: () => {},
});

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const featureFlags = useSelector((state: any) => {
    return state.app.featureFlags as string[];
  });

  const [theme, setTheme] = useState<string | null>(
    sessionStorage.getItem('themeMode') || 'pratus'
  );

  const setNewTheme = (newTheme: 'legacy' | 'pratus') => {
    const themeElements = document.querySelectorAll('#theme');
    if (themeElements.length > 1) {
      console.error('Multiple theme elements found in the DOM.');
    }
    if (themeElements.length > 0) {
      themeElements[0].remove();
    }
    if (themes[newTheme]) {
      document.head.appendChild(themes[newTheme]);
    } else {
      import(/* webpackChunkName: "themePratus" */ './pratus.scss').then(() => {
        themes['pratus'] = document.querySelector('#theme');
      });
    }

    setTheme(newTheme);
    sessionStorage.setItem('themeMode', newTheme);
  };
  const toggleTheme = () =>
    setNewTheme(theme === 'legacy' ? 'pratus' : 'legacy');

  useEffect(() => {
    if (theme === 'legacy') {
      setNewTheme('legacy');
      dispatch(
        changeFeatureFlags([
          ...featureFlags.filter((flag) => {
            return flag !== 'NEW_UI';
          }),
        ])
      );
    }
  }, []);

  return (
    <Context.Provider
      value={{ theme: theme as 'legacy' | 'pratus', toggleTheme }}
    >
      {children}
    </Context.Provider>
  );
};

export default ThemeProvider;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeFeatureFlags } from 'actions/featureFlagActions';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const featureFlagLabels = [
  'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE',
  'FULL_IAP_208',
  'LOCATION_PICKER_FILE',
  'BRANCH_ON_ROSTER',
  'MAP_GEOLOCATION',
  'SUBSCRIPTION_MANAGEMENT',
  'GENERATE_TEAM_SITE',
  'STRIPE',
  'DISASTERGPT',
  'PLANAI',
  'ICS EXPORT',
  'POWER_OUTAGES',
  'NAVY_CEAD_2ND_DELIVERABLE',
  'OFFLINE_ENVIRONMENT',
  'NEW_ICS', // WARNING: REMOVE todo_remove_new_ics_feature_flag_on HANDLING ON INCIDENT CREATION BEFORE REMOVING THIS FEATURE FLAG
  'GRAPHISTRY_STABILITY',
  'SELF_HOST_KNOWLEDGE_BASE',
  'STREAMLINE_PLATFORM',
  'SHOW_WILDFIRE_EVENTS',
];

export default function FeatureFlags() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const handleChange = (value) => {
    const s = new Set(featureFlags);
    if (s.has(value)) {
      s.delete(value);
    } else {
      s.add(value);
    }
    dispatch(changeFeatureFlags(Array.from(s)));
  };

  return (
    <>
      <div className="site-main">
        <div className="site-main__profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button onClick={() => navigate(-1)}>
                        <SharedIcon
                          iconName="keyboard_arrow_left"
                          classes="me-2"
                        />
                        Back
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Feature Flags
                    </li>
                  </ol>
                </nav>
                <h4 className="mb-4">Feature Flags</h4>
                <div className="form-block mb-4">
                  <div className="row">
                    {featureFlagLabels.map((feature) => (
                      <div
                        key={'FeatureFlags-' + feature}
                        className="col-md-6 col-xl-4 d-inline-flex my-2"
                      >
                        <StylishNewCheckbox
                          className={'break-word'}
                          checked={featureFlags.includes(feature)}
                          onClick={() => handleChange(feature)}
                          label={feature}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <StylishNewButton
                  className={'button--primary'}
                  onClick={() => navigate('/map')}
                >
                  Return to Pratus
                </StylishNewButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';

import {
  tenantId,
  sharepointDriveId
} from '../DisasterGPTConstants';

// Define the query key
const keys = {
  sharepointFiles: 'sharepointFiles',
};

// Hook to fetch SharePoint files
export const useSharepointFiles = () => {
  return useQuery({
    queryKey: [keys.sharepointFiles],
    queryFn: async () => {
      // Include tenantId and sharepointDriveId as query parameters
      const { data } = await axios.get(`${config.apiGateway.sharepoint_files}`, {
        params: {
          tenantId,
          sharepointDriveId,
        },
      });
      return data;
    },
    staleTime: 60000, // Cache results for 60 seconds
  });
};

import {
  ADD_WORKSPACE_TO_WORKSPACE_LIST,
  CHANGE_SELECTED_WORKSPACE,
  CHANGE_SELECTED_WORKSPACE_NAME,
  CHANGE_WORKSPACE_TIMEZONE,
  UPDATE_ALL_WORKSPACE_LIST,
} from "./workspaces.constants";

export const changeSelectedWorkSpace = (workspaceId) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_WORKSPACE,
      payload: workspaceId,
    });
  };
};

export const changeSelectedWorkSpaceName = (workspaceName, type) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_WORKSPACE_NAME,
      payload: { workspaceName, type },
    });
  };
};


export const updateWorkSpaceTimeZone = (timezone, timezoneOffset) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_WORKSPACE_TIMEZONE,
      payload: { timezone, timezoneOffset },
    });
  };
};

export const updateAllWorkSpaceList = (workspaceList) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ALL_WORKSPACE_LIST,
      payload: workspaceList,
    });
  };
};

export const updateWorkSpaceListById = (workspace) => {
  return (dispatch) => {
    dispatch({
      type: ADD_WORKSPACE_TO_WORKSPACE_LIST,
      payload: workspace,
    });
  };
};
